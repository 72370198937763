/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "../node_modules/ol/ol.css";
.split-pane-visible > .split-pane-side {
  min-width: 150px !important;
  max-width: 350px !important;
}

ion-menu {
  background: white;
}

ion-header {
  background: rgb(255, 255, 255);
}

ion-content {
  background: rgba(255, 0, 255, 0);
}

/* GLASSY Cards */
/* Glasmorphistic ion card */
ion-card {
  border-radius: 8px !important;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(16px);
  margin: auto;
  box-shadow: 12px 12px 10px 1px rgba(0, 0, 0, 0.3);
}

#center-any-object {
  display: flex;
  justify-content: center;
}

ion-popover {
  --background: white;
}

.index_backgroundColorAnimated {
  z-index: -1;
  height: 100vh;
  overflow: auto;
  background: linear-gradient(313deg, rgb(51, 49, 2), rgb(37, 51, 2), rgb(13, 51, 2), rgb(2, 51, 27), rgb(2, 32, 51));
  background-size: 1000% 1000%;
  -webkit-animation: bgndGradAnimation 29s ease infinite;
  -moz-animation: bgndGradAnimation 29s ease infinite;
  animation: bgndGradAnimation 29s ease infinite;
}

@-webkit-keyframes bgndGradAnimation {
  0% {
    background-position: 1% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0%;
  }
}
@-moz-keyframes bgndGradAnimation {
  0% {
    background-position: 1% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0%;
  }
}
@keyframes bgndGradAnimation {
  0% {
    background-position: 1% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0%;
  }
}
/* Alert Boxes like used in qr creation if list is not available */
/* The alert message box */
.alert {
  padding: 20px;
  text-align: center;
  background-color: #b3a994; /* Red */
  color: #000000;
  margin-bottom: 15px;
}

/* The close button */
.closebtn {
  margin-left: 15px;
  color: #ffffff;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}

ion-checkbox {
  --size: 32px;
  --background-checked: #477301;
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid #aba217;
}